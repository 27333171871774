"use client";
import React from "react";
import { Nunito_Sans } from "next/font/google";
import { Header } from "@/shared/index";
import { usePathname } from "next/navigation";
import { Company } from "@/modules/company/domain/ICompany";
import { cn } from "@/utils/cn";
import "./globals.css";

const nunito = Nunito_Sans({
	weight: ["200", "300", "400", "500", "700", "900"],
	subsets: ["latin"],
	variable: "--font-family-primary",
});

export type ChildrenProps = {
	children: React.ReactNode;
	currentCompany: Company;
};

const TemplateLayout = ({ children, currentCompany }: ChildrenProps) => {
	const pathname = usePathname();
	const isLogin = pathname === "/usuarios/acceso";
	return (
		<html>
			<head lang="es">
				<link rel="icon" href="/favicons/favicon.svg" type="image/svg+xml" />
			</head>
			<body className={`${nunito.variable}`}>
				<main
					className={cn(
						"w-dvw flex min-h-screen h-full",
						pathname.includes("acceso")
							? "bg-secondary-600"
							: "bg-[#ECF0F2]"
					)}
				>
					<Header
					
						logged={!isLogin ? true : false}
						currentCompany={currentCompany}
					/>
					<div className="flex justify-center items-center w-full py-8 px-8 max-w-full bg-grey-25 overflow-scroll">
						{children}
					</div>
				</main>
			</body>
		</html>
	);
};

export default TemplateLayout;
