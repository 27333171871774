import { revalidateGetOperationsByMe } from "@/modules/operations/(trenes)/infraestructure/getOperationByMe";
import {
	QueryClient,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { IOperation } from "@umahealth/entities";
import { useEffect, useState } from "react";
import { Socket } from "socket.io-client";

/**
 * Interface representing the status of a call.
 * @interface
 */
export interface progressStatus {
	status: "in-progress";
	operationId: IOperation["id"];
	appointment_call_id: string;
	doctorName: string;
	/** Si el doctor te esta llamado es true, si sólo esta revisando es false */
	inCall: boolean;
}

export interface finishedStatus {
	status: "finished";
	operationId: IOperation["id"];
	apto: boolean;
	operator: IOperation["user"];
}

type Status = progressStatus | finishedStatus;

/**
 * Custom hook to handle incoming call status updates via a Socket.io connection.
 *
 * @param {Socket | null} socket - The Socket.io instance used for listening to events.
 * @param {string} operationId - The unique identifier for the current operation.
 * @returns {progressStatus | null} The current status of the call, or null if no call status has been received.
 */
export function useGetIncomingCall(socket: Socket | null) {
	const [call, setCall] = useState<Status | null>(null);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (socket) {
			socket.onAny((event) => {
				console.log(
					"se reportó un evento, event:",
					event,
					event.message
				);
			});

			/* Emito un socket para suscribirme y empezar a recibir notificationes */
			console.log("time to emit apt-review-notification-subscription");
			socket.emit("apt-review-notification-subscription");
			console.log("emited");

			socket.on(
				"apt-review-update-status",
				async (call: Omit<progressStatus, "status">) => {
					setCall({
						...call,
						status: "in-progress",
					});
					console.log("llego algo a apt-review-update-status:", call);
					queryClient.clear();
					await revalidateGetOperationsByMe()
				}
			);

			socket.on(
				"apt-review-finished",
				async(call: Omit<finishedStatus, "status">) => {
					console.log("llego algo a apt-review-finished:", call);

					setCall({
						...call,
						status: "finished",
					});
					queryClient.clear();
					await revalidateGetOperationsByMe();
				}
			);

			/** Si se crea una operación borro queryClient */
			socket.on("new-operations-appointments", () => {
				queryClient.clear();
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket, call]);

	return call;
}
