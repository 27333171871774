import * as React from "react";
import * as Toast from "@radix-ui/react-toast";
import { Paragraph } from "@umahealth/occipital";
import { Icon } from "@umahealth/occipital/client";
import Link from "next/link";

export function CallingCardUIComponent({
	doctorName,
	href,
}: {
    doctorName: string,
    href: string
}) {

	const [open, setOpen] = React.useState(true)

	return (
		<Toast.Provider swipeDirection="up">
			<Toast.Root
				className=" relative flex flex-col h-full p-12 rounded-2xl bg-[#00151D] opacity-90 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[swipe=cancel]:translate-y-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
				open={open}
			>
				<Toast.Title className="text-background-light text-3xl text-center mb-10 font-semibold">
					Videollamada entrante...
				</Toast.Title>
				<Toast.Description asChild>
					<Paragraph
						className="text-center font-semibold"
						color="text-background-patient"
					>
						{doctorName}
					</Paragraph>
				</Toast.Description>
				<Toast.Action
					className="bottom-0 absolute mb-12 w-[420px]"
					asChild
					altText="Goto schedule to undo"
				>
					<Link
						href={href}
						onClick={() => setOpen(false)}
						className="bg-success-500 rounded-full px-12 h-[72px] flex items-center justify-center"
					>
						<Icon
							className="mr-2"
							name="call"
							size="size-6"
							color="text-background-patient"
						/>{" "}
						<span className="text-background-light text-2xl">
							Atender
						</span>
					</Link>
				</Toast.Action>
			</Toast.Root>
			<Toast.Viewport className="[--viewport-padding:_25px] left-0 right-0 ml-auto mr-auto fixed top-0 flex flex-col p-[var(--viewport-padding)] gap-[10px] w-[528px] h-[379px] max-w-[100vw] m-0 list-none z-[10] outline-none" />
		</Toast.Provider>
	);
};


