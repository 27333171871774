import {
	DialogRoot,
	DialogPortal,
	DialogOverlay,
	DialogContent,
	DialogTitle,
	DialogDescription,
	DialogClose,
} from "@umahealth/occipital/client";
import { Button, Title, Paragraph } from "@umahealth/occipital"; // Asegúrate de importar tus componentes de la biblioteca correcta
import { ApprovalStatusCard } from "../../cards/ApprovalStatusCard/ApprovalStatusCard";
import { StatusImage } from "../ConfirmationModal/ConfirmationModal";

interface DialogProps {
	children?: React.ReactNode;
	open?: boolean;
	defaultOpen?: boolean;
	onOpenChange?(open: boolean): void;
	modal?: boolean;
}

export function EndCallModalDialogRoot({
	children,
	modal = true,
	...props
}: DialogProps) {
	return (
		<DialogRoot modal={modal} {...props}>
			{children}
		</DialogRoot>
	);
}

export function EndCallModal({
	approved,
	fullname,
	legajo,
	operationId
}: {
	approved?: boolean;
	fullname?: string;
	legajo?: string;
	operationId?: number
}) {
	return (
		<DialogPortal>
			<DialogOverlay />
			<DialogContent className="flex items-center flex-col p-8">
				<StatusImage
					className="my-6"
					status={approved ? "success" : "warning"}
				/>
				<DialogTitle asChild>
					<Title
						hierarchy="h1"
						color="text-primary-800"
						size="text-l"
						weight="font-semibold"
						className="mb-4"
					>
						Nueva revisión finalizada
					</Title>
				</DialogTitle>
				<DialogDescription asChild>
					<Paragraph className="text-center pb-6">
						El operador {approved ? "" : "no"} podra comenzar su
						jornada laboral
					</Paragraph>
				</DialogDescription>
				<ApprovalStatusCard
					className="mb-6"
					fullname={fullname}
					legajo={legajo}
					approval={approved}
				/>
				<section
					className="py-4 px-6 w-full"
					aria-label="acciones posibles"
				>
					<Button
						variant="filled"
						size="full"
						type="link"
						href={`{/operaciones/${operationId}}`}
						className="mb-4"
					>
						Ir a la evaluación
					</Button>
					<DialogClose asChild>
						<Button variant="text" size="full" type="button">
							Cerrar
						</Button>
					</DialogClose>
				</section>
			</DialogContent>
		</DialogPortal>
	);
}
