'use client'
import { ReactNode, useState } from "react";
import { useSocket } from "../Chat/useSocket"
import { useGetIncomingCall } from "../useGetCall";
import { CallingCardUIComponent } from "./CallingCardUIComponent";
import { usePathname } from "next/navigation";
import { EndCallModal, EndCallModalDialogRoot } from "../../Modals/endCallModal/EndCallModal";
import { Toast } from "@umahealth/occipital";

export function CallingCard({children } : {children : ReactNode}){

    const socket = useSocket()
    const getCallStatus = useGetIncomingCall(socket)
	const pathName = usePathname()
	const [open, setOpen] = useState(true)

	/** No quiero que salga la callingCard en la videollamada */
	if (pathName.includes("videollamada")){
		return children
	}

	if (getCallStatus?.status === "in-progress" && getCallStatus?.inCall === true) {
		return (
			<>
				<CallingCardUIComponent
					doctorName={getCallStatus.doctorName}
					href={`/operaciones/crear/${getCallStatus.operationId}/videollamada`}
				/>
				<>{children}</>
			</>
		);
	}

	if (getCallStatus?.status === "in-progress" && !getCallStatus?.inCall){
		return (
			<>
				<Toast
					description={`Un doctor empezó a revisar una ficha.`}
					open={open}
					onOpenChange={(newOpenState) => {
						setOpen(!newOpenState);
					}}
					duration={1000}
					type="info"
				/>
				<>{children}</>
			</>
		);
	}

	if (getCallStatus?.status === "finished") {
			return (
				<>
					<EndCallModalDialogRoot defaultOpen>
						<EndCallModal
							approved={getCallStatus.apto}
							fullname={`${getCallStatus.operator.firstName} ${getCallStatus.operator.lastName}`}
							legajo={getCallStatus.operator.nomina}
							operationId={getCallStatus.operationId}
						/>
					</EndCallModalDialogRoot>
					{children}
				</>
			);
		}
		
	return <>
	{children}
	</>
}