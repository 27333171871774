import { alertAIApiUrl } from "@/config/endpoints";
import { auth } from "@/config/firebaseApp";
import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

/**
 * Custom hook to start a WebSocket connection using Socket.IO.
 * The socket is authenticated with a Firebase token.
 *
 * @returns {Socket | null} The initialized socket or null if not yet connected.
 */
export function useSocket() {
	const [socket, setSocket] = useState<Socket | null>(null);

	useEffect(() => {
		let isMounted = true;

		const initializeSocket = async () => {
			try {
				const token = await auth?.currentUser?.getIdToken();
				if (isMounted) {
					const socket = io(String(alertAIApiUrl), {
						auth: {
							token: `Bearer ${token}`,
						},
					});
					socket.connect()
					socket.on("connect", () => {
						console.log('socket is connected')
					});
					setSocket(socket);
				}
			} catch (error) {
				console.error("Error initializing socket:", error);
			}
		};

		initializeSocket();

		return () => {
			isMounted = false;
			socket?.disconnect();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return socket;
}
